import React, { useCallback, useMemo, useState } from 'react';

import { compact, filter, find } from 'lodash';
import Tooltip from '@mui/material/Tooltip';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';

import { useTranslation } from 'app/translations/useTranslation';
import { Company } from 'model/Company';
import { useCompanies } from 'services/companies/state/useCompanies';
import useMUIDataTableDefaultOptions from 'utlis/MUIDataTables/useMUIDataTableDefaultOptions';
import useMUIDataTablePersistentExpandedRows from 'utlis/MUIDataTables/useMUIDataTablePersistentExpandedRows';

import TableFiltersProvider from 'components/dataTables/filters/TableFiltersProvider';
import TableFiltersToolbar from 'components/dataTables/filters/TableFiltersToolbar';
import PackageWarning from 'components/Icons/PackageWarning';
import DialpadOff from 'components/Icons/DialpadOff';

import CompanyImage from '../../../../Companies/coponents/CompanyImage';
import { OnHold, Order, OrderComment, OrderStage, XDeliverer } from '../../../model/Order';
import { useOrders } from '../../state/useOrders';
import { Filters } from '../Filters/Filters';
import { OrderRowDetails } from './components/Row/components/OrderRowDetails/OrderRowDetails';
import { styled } from '@mui/material/styles';
import { Multiplier } from '../../../../MultiplierPage/model/Multiplier';
import OnHoldIcon from '../../../../../components/Icons/OnHoldIcon';

interface Props {}

export function OrderList(props: Props) {
  const { t } = useTranslation('order');

  const { orderListing } = useOrders();
  const { companies } = useCompanies();

  const ordersExtended = useMemo(
    () =>
      orderListing?.list.map((order: Order) => ({
        order,
        company: companies?.find((company) => company.id === order.companyId),
        inStage: order.stage?.isOngoing
          ? Math.floor((new Date().getTime() - new Date(order.stage.beganAt).getTime()) / 1000 / 60)
          : undefined,
        realizedWithin:
          order.acceptedAt && order.deliveredAt
            ? Math.floor(
                (new Date(order.deliveredAt).getTime() - new Date(order.acceptedAt).getTime()) /
                  1000 /
                  60,
              )
            : undefined,
        ...(order.pickupPoint && {
          pickupPointName:
            order.pickupPoint?.hasHashedSensitiveData === true
              ? t('list.values.pickupPoint')
              : order.pickupPoint.name,
        }),
        totalRealizationTime: Math.floor(
          (new Date(order.deliveredAt ?? new Date()).getTime() -
            new Date(order.plannedAt ?? order.createdAt).getTime()) /
            1000 /
            60,
        ),
        flags: {
          emergencyDelivery: order.emergencyDelivery,
          noVerificationCodeReason: order.noVerificationCodeReason,
          multiplier: order.multiplier,
          onHold: order.onHold,
        },
      })),
    [orderListing, companies],
  );

  const { onExpand, rowsExpanded } = useMUIDataTablePersistentExpandedRows<{
    order: Order;
  }>(ordersExtended, (row: { order: Order }) => row.order.id);

  const defaultOptions = useMUIDataTableDefaultOptions();
  const options = useMemo<MUIDataTableOptions>(
    () => ({
      ...defaultOptions,
      onRowExpansionChange: onExpand,
      expandableRows: true,
      expandableRowsOnClick: true,
      expandableRowsHeader: false,
      rowsExpanded,
      renderExpandableRow: (rowData, rowMeta) => (
        <TableRow>
          <TableCell colSpan={rowData.length + 1}>
            <OrderRowDetails order={ordersExtended[rowMeta.dataIndex].order} />
          </TableCell>
        </TableRow>
      ),
      search: false,
      searchOpen: false,
      searchAlwaysOpen: false,
      filter: false,
      viewColumns: false,
      download: true,
      customSearchRender: () => <></>,
      downloadOptions: {
        filterOptions: {
          useDisplayedColumnsOnly: false,
          useDisplayedRowsOnly: true,
        },
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        const customRenderColumns = [
          {
            name: 'flags',
            renderer: (value: any) =>
              filter([
                value.emergencyDelivery ? t('labels.emergencyDelivery') : undefined,
                !!value.noVerificationCodeReason
                  ? `${t('labels.noVerificationCodeReason')}: ${value.noVerificationCodeReason}`
                  : undefined,
              ]).join(';'),
          },
        ];
        data.map((order: any) => {
          order.data = order.data.map((value: any, index: number) => {
            const renderer = columns[index];
            const customColumn = find(customRenderColumns, { name: renderer.name });
            if (customColumn) {
              return customColumn.renderer
                ? customColumn.renderer(value)
                : renderer.customBodyRender(value);
            }
            return value;
          });
          return order;
        });
        return `${buildHead(columns)}${buildBody(data)}`.trim();
      },
    }),
    [defaultOptions, ordersExtended],
  );

  const columns = useMemo<MUIDataTableColumn[]>(
    () => [
      {
        name: 'company',
        label: t('labels.company'),
        options: {
          customBodyRender: (company: Company | undefined) =>
            !!company && !!company.imageFile ? (
              <CompanyImage company={company} />
            ) : (
              company?.name ?? null
            ),
          download: false,
          filter: false,
        },
      },
      {
        name: 'company.name',
        label: t('labels.company'),
        options: {
          display: false,
        },
      },
      {
        name: 'order.no',
        label: t('list.headers.no'),
      },
      {
        name: 'order.stage',
        label: t('list.headers.status'),
        options: {
          customBodyRender: (stage: OrderStage) => {
            const statusLabel = t(`status.${stage.status}`);
            if (stage.statusReason === undefined) {
              return statusLabel;
            }

            const detailsTranslation = t(`statusReason.groups.${stage.statusReason.group}`);
            return compact([statusLabel, detailsTranslation]).join(' - ');
          },
        },
      },
      {
        name: 'order.region',
        label: t('list.headers.region'),
      },
      {
        name: 'order.pickupPoint.city',
        label: t('list.headers.city'),
      },
      {
        name: 'pickupPointName',
        label: t('list.headers.pickupPointName'),
      },
      {
        name: 'order.xDeliverer',
        label: t('list.headers.xDeliverer'),
        options: {
          customBodyRender: (xDeliverer: XDeliverer) =>
            !!xDeliverer
              ? [xDeliverer.firstName, xDeliverer.lastName].filter((n) => !!n).join(' ')
              : t('list.values.none'),
        },
      },
      {
        name: 'order.scheduledSlotRange',
        label: t('labels.scheduledSlotRange'),
        options: {
          filter: false,
          customBodyRender: (slotRange: [number, number] | undefined) => {
            if (!slotRange) {
              return null;
            }

            const from = new Date(slotRange[0]);
            const to = new Date(slotRange[1]);
            const isSameDay =
              from.getFullYear() === to.getFullYear() &&
              from.getMonth() === to.getMonth() &&
              from.getDate() === to.getDate();

            return (
              <>
                {t('common:datetime.datetime', {
                  date: from,
                })}{' '}
                -{' '}
                {t(isSameDay ? 'common:datetime.time' : 'common:datetime.datetime', {
                  date: to,
                })}
              </>
            );
          },
        },
      },
      {
        name: 'inStage',
        label: t('list.headers.timeInStage'),
        options: {
          filter: false,
          customBodyRender: (inStage: number | undefined) =>
            inStage === undefined ? <>---</> : <>{`${inStage} ${t('common:units.minuteShort')}`}</>,
        },
      },
      {
        name: 'totalRealizationTime',
        label: t('list.headers.totalRealizationTime'),
        options: {
          filter: false,
          customBodyRender: (totalRealizationTime: number | undefined) =>
            totalRealizationTime === undefined ? (
              <>---</>
            ) : (
              <>{`${totalRealizationTime} ${t('common:units.minuteShort')}`}</>
            ),
        },
      },
      {
        name: 'realizedWithin',
        label: t('list.headers.realizedWithin'),
        options: {
          filter: false,
          customBodyRender: (realizedWithin: number | undefined) =>
            realizedWithin === undefined ? (
              <>---</>
            ) : (
              <>{`${realizedWithin} ${t('common:units.minuteShort')}`}</>
            ),
        },
      },
      {
        name: 'flags',
        label: t('list.headers.flags'),
        options: {
          filter: false,
          customBodyRender: (flags: {
            emergencyDelivery: boolean;
            noVerificationCodeReason?: string;
            multiplier?: Multiplier;
            onHold?: OnHold;
          }) => (
            <>
              {flags.onHold?.currentlyOnHold && (
                <Tooltip title={`${t('labels.onHold')}: ${flags.onHold?.driversNote ?? ' - '}`}>
                  <span>
                    <OnHoldIcon fontSize={'large'} color={'warning'} />
                  </span>
                </Tooltip>
              )}
              {flags.multiplier && (
                <>
                  {flags.multiplier.deactivatedAt ? (
                    <Tooltip title={t('labels.multiplierDeactivatedInfo') as string}>
                      <MultiplierDeactivatedSpan>
                        [{flags.multiplier.multiplier}x]
                      </MultiplierDeactivatedSpan>
                    </Tooltip>
                  ) : (
                    <Tooltip title={t('labels.multiplierInfo') as string}>
                      <MultiplierSpan>[{flags.multiplier.multiplier}x]</MultiplierSpan>
                    </Tooltip>
                  )}
                </>
              )}
              {flags.emergencyDelivery && (
                <Tooltip title={t('labels.emergencyDelivery') as string}>
                  <span>
                    <PackageWarning />
                  </span>
                </Tooltip>
              )}
              {!!flags.noVerificationCodeReason && (
                <Tooltip
                  title={`${t('labels.noVerificationCodeReason')}: ${
                    flags.noVerificationCodeReason
                  }`}
                >
                  <span>
                    <DialpadOff />
                  </span>
                </Tooltip>
              )}
            </>
          ),
        },
      },
      {
        name: 'order.comments.lastComment',
        label: t('list.headers.lastComment'),
        options: {
          customBodyRender: (comment: OrderComment) =>
            !!comment ? comment.comment : t('list.values.none'),
        },
      },
    ],
    [t],
  );

  return (
    <TableFiltersProvider filters={<Filters />}>
      <MUIDataTable
        title=""
        data={ordersExtended}
        columns={columns}
        options={options}
        components={{ TableToolbar: TableFiltersToolbar }}
      />
    </TableFiltersProvider>
  );
}

const MultiplierSpan = styled('span')(
  ({ theme }) => `
  font-size: 22px;
  color: ${theme.colors.experimental.success};
`,
);

const MultiplierDeactivatedSpan = styled('span')(
  ({ theme }) => `
  font-size: 22px;
  color: ${theme.colors.primary.mainDisabled};
`,
);
